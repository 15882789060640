<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Calculate the
        <stemble-latex content="$\text{pH}$" />
        of a
        <chemical-latex content="KOCl" />
        solution that is
        <number-value :value="molarity" unit="\text{M}" />
        , given that
        <stemble-latex content="$\text{K}_\text{a}$" />
        of
        <chemical-latex content="HOCl" />
        is
        <stemble-latex content="$3.0 x 10^{-8}$" />
        and
        <stemble-latex content="$\text{K}_\text{w}$" />
        of water is
        <stemble-latex content="$1 x 10^{-14}.$" />
      </p>

      <calculation-input
        v-model="inputs.input1"
        class="mb-5"
        prepend-text="$\text{pH}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import NumberValue from '../NumberValue';

export default {
  name: 'ChemUPEI2210A6Q5',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    molarity() {
      return this.taskState.getValueBySymbol('molarity').content;
    },
  },
};
</script>
